exports.components = {
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-components-admin-index-js": () => import("./../../../src/pages/components/Admin/index.js" /* webpackChunkName: "component---src-pages-components-admin-index-js" */),
  "component---src-pages-components-home-index-js": () => import("./../../../src/pages/components/Home/index.js" /* webpackChunkName: "component---src-pages-components-home-index-js" */),
  "component---src-pages-components-login-index-js": () => import("./../../../src/pages/components/Login/index.js" /* webpackChunkName: "component---src-pages-components-login-index-js" */),
  "component---src-pages-heter-iska-index-js": () => import("./../../../src/pages/heter-iska/index.js" /* webpackChunkName: "component---src-pages-heter-iska-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investments-index-js": () => import("./../../../src/pages/investments/index.js" /* webpackChunkName: "component---src-pages-investments-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-offshore-feeder-index-js": () => import("./../../../src/pages/offshore-feeder/index.js" /* webpackChunkName: "component---src-pages-offshore-feeder-index-js" */),
  "component---src-pages-offshore-notes-index-js": () => import("./../../../src/pages/offshore-notes/index.js" /* webpackChunkName: "component---src-pages-offshore-notes-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-product-programs-index-js": () => import("./../../../src/pages/product-programs/index.js" /* webpackChunkName: "component---src-pages-product-programs-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */),
  "component---src-pages-us-investors-index-js": () => import("./../../../src/pages/us-investors/index.js" /* webpackChunkName: "component---src-pages-us-investors-index-js" */),
  "component---src-template-news-index-js": () => import("./../../../src/template/News/index.js" /* webpackChunkName: "component---src-template-news-index-js" */)
}

